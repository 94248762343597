body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root{
  font-size: 16px;
}

*{
  box-sizing: border-box !important;
  outline: none !important;
}

.bg-header{
  background-color: #4B55A1;
}

.btn-background{
  background: #EC6159;
}

.primary-text-color{
  color: #4B55A1;
}

nav > a{
  font-size: 1.2rem;
  text-decoration: none;
  text-transform: uppercase;
}

a.active{
  color: #EC6159;
  border-bottom: 2px solid #EC6159;
}

a:hover{
  text-decoration: none;
}

.card-height{
  height: 450px;
}

.image-size{
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.text-favourite{
  color: #EC6159;
}

.rm-btn-bg{
  background-color: #EC6159;
}

.pagination li.option{
  cursor: pointer;
}

.pagination li span{
  color: #000;
}

.message{
  position: fixed;
  top: 10px;
  right: 0;
  z-index: 1030;
}

